import React, { Component } from 'react';
import MailchimpSignupWrapper from 'react-mailchimp-subscribe';
import EmailForm from './emailForm';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

const ErrorMessage = ({ status, message }) => (
  <SlideDown className="field" closed={status === 'error'}>
    <div className="field">
      <div
        className="notification is-warning is-light"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  </SlideDown>
);

const SuccessMessage = ({ message }) => (
  <SlideDown className="field">
    <div
      className="field notification is-success is-light"
      style={{ color: 'green' }}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  </SlideDown>
);

const url =
  'https://engineeringmonthly.us18.list-manage.com/subscribe/post?u=41231896c1830dd09f1208151&amp;id=4fab40d329';
class SignupForm extends Component {
  render() {
    return (
      <>
        <MailchimpSignupWrapper
          url={url}
          render={({ subscribe, status, message }) => (
            <>
              {status === 'error' && <ErrorMessage message={message} />}
              {status === 'success' && <SuccessMessage message={message} />}
              <h2 className="heading">Subscribe</h2>
              <EmailForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            </>
          )}
        />
      </>
    );
  }
}

export default SignupForm;
