import React from 'react';
// import ReactDOM from 'react-dom';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className="field">
      <p className="control has-icons-left">
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input input" {...field} {...props} />
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon="envelope" />
        </span>
      </p>
      {meta.touched && meta.error ? (
        <p className="help is-danger">{meta.error}</p>
      ) : null}
    </div>
  );
};

const MyCheckbox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div className="field">
      <label ariaLabel="consent" className="checkbox" />
      <input type="checkbox" {...field} {...props} />
      {children}
      {meta.touched && meta.error ? (
        <p className="help is-danger">{meta.error}</p>
      ) : null}
    </div>
  );
};

const SubmitButton = ({ children, ...props }) => {
  const [meta, isSubmitting] = useField({ ...props });
  const { status } = props;
  return (
    <button
      {...props}
      disabled={isSubmitting}
      className="button is-primary is-link"
      type="submit"
    >
      {children}
    </button>
  );
};

// And now we can use these
const SignupForm = ({ ...props }) => {
  let { status, message } = props;
  return (
    <Formik
      initialValues={{
        email: '',
        acceptedTerms: false, // added for our checkbox
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        acceptedTerms: Yup.boolean()
          .required('Required')
          .oneOf([true], 'You must accept the terms and conditions.'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { onValidated } = props;
        setSubmitting(true);

        onValidated({
          EMAIL: values.email,
        });

        // group id:value for Javascript group
        // 'group[212930]-212930-0': 1,
        setSubmitting(false);
      }}
    >
      <>
        <Form className="full-width">
          <MyTextInput name="email" type="email" placeholder="Email Address" />
          <MyCheckbox name="acceptedTerms">
            {' '}
            I accept the <Link to="/privacy-policy">terms and conditions</Link>
          </MyCheckbox>
          <SubmitButton status={status}>Submit</SubmitButton>
        </Form>
      </>
    </Formik>
  );
};

export default SignupForm;
