import React from 'react';
import Layout from '../../components/Layout';
import EmailSignup from '../../components/mailChimpSignup';
// Initialize FontIcons
import '../../components/Fontawesome';

export default class NewsletterIndex extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="columns is-desktop is-centered">
              <div className="column is-three-quarters-desktop">
                <h1 className="title">Newsletters</h1>
                <div className="columns">
                  <div className="column is-7">
                    <p>
                      If you're anything like me, you have a million things to
                      do — so many things you want to learn. How would your life
                      change if you stopped chasing the critical information to
                      keep you relevant in a quickly changing world and instead
                      let it come to you?{' '}
                    </p>
                    <blockquote cite="Albert Einstein" className="blockquote">
                      If we knew what it was we were doing, it would not be
                      called research, would it?
                    </blockquote>
                  </div>
                  <div className="column">
                    <div className="tile is-vertical box">
                      <EmailSignup />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
